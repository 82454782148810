import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Nillable } from "../../../../shared/types/nillable";
import { Observable, of } from "rxjs";
import { SelectItem } from "../../../../shared/blocks/dropdown/models/select-item";
import { StudentPassFilterForm } from "./types/student-pass-filter-form";
import { StudentPassFilterFormData } from "./types/models/student-pass-filter-form-data";
import { isNil } from "lodash-es";
import { StatsService } from "src/app/shared/services/statistics/stats.service";
import {
  CourseCriteria,
  CriteriaBarPayload,
} from "src/app/shared/interfaces/student-pass-criteria-api";
import { PassCriteriaType } from "src/app/shared/enums/pass-criteria-type";
import { CRITERIA_TITLES_TEACHER } from "src/app/shared/consts/stats-criteria-titles";
import { RequestStatus } from "src/app/shared/interfaces/request-status";
import { Store } from "@ngrx/store";
import { StatsCriteriaState } from "src/app/store/reducers/stats/stats-criteria.reducer";

@Component({
  selector: "student-pass-filter-form",
  templateUrl: "./student-pass-filter-form.component.html",
  styleUrls: ["./student-pass-filter-form.component.scss"],
})
export class StudentPassFilterFormComponent implements OnChanges {
  @Input() criteria: CourseCriteria;
  @Output() passSystemFormSave = new EventEmitter<CriteriaBarPayload>();

  suffix = "%";
  isCriteriaEditable = false;
  readonly getStatsCriteriaStatus$: Observable<RequestStatus> =
    this.store.select((state) => state.statsCriteria.status);

  readonly form = this.buildForm();
  readonly passSelectItems$ = this.passSelectItems();
  readonly currentRule$ = this.stats.currentPartialGradeId;
  readonly criteriaTitles = CRITERIA_TITLES_TEACHER;
  readonly PassCriteriaType = PassCriteriaType;

  mappedCriterias: CriteriaBarPayload[] = [];

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly stats: StatsService,
    private readonly store: Store<{
      statsCriteria: StatsCriteriaState;
    }>,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { criteria } = changes;

    if (criteria?.currentValue?.criteria_type === PassCriteriaType.THRESHOLD) {
      this.mapDataToCriterias();
      this.form.patchValue(
        this.prepareFormData({ passFrom: this.criteria.threshold }),
      );
    } else {
      this.mappedCriterias = this.criteriaTitles.map((criteria) => ({
        name: criteria,
        exercises: [],
      }));
    }
  }

  handleSaveClick(): void {
    if (this.form.invalid) {
      return;
    }

    const studentPassForm: CriteriaBarPayload = {
      criteria_type: 1,
      threshold: +this.form.controls.passFrom.value.slice(0, -1),
      exercises: this.mappedCriterias[0].exercises,
      required_exercises: this.mappedCriterias[1].exercises,
    };

    this.passSystemFormSave.emit(studentPassForm);
  }

  mapDataToCriterias(): void {
    const { exercises, required_exercises } = this.criteria;

    this.mappedCriterias = this.criteriaTitles.map((criteria, index) => ({
      name: criteria,
      exercises:
        criteria == "COURSES.COURSE.RANGE_ASSESSMENT"
          ? exercises
          : required_exercises,
    }));
  }

  onCriteriaData(updatedCriteria: CriteriaBarPayload): void {
    this.mappedCriterias = this.criteriaTitles.map((criteriaName, index) => ({
      name: criteriaName,
      exercises:
        criteriaName == "COURSES.COURSE.RANGE_ASSESSMENT"
          ? updatedCriteria.exercises
          : updatedCriteria.required_exercises,
    }));
  }

  onEditableChanged(isEditable: boolean, name: string): void {
    if (isEditable) {
      this.stats.setCurrentPartialGrade(name);
      this.isCriteriaEditable = true;
    } else {
      this.stats.setCurrentPartialGrade(null);
      this.isCriteriaEditable = false;
    }
  }

  private buildForm(): FormGroup<StudentPassFilterForm> {
    return this.formBuilder.group({
      passFrom: this.formBuilder.control<Nillable<string>>(null, [
        Validators.min(0),
        Validators.max(100),
        Validators.required,
      ]),
    });
  }

  prepareFormData(data: Nillable<StudentPassFilterFormData>): {
    passFrom: string;
  } {
    return isNil(data) ? null : { passFrom: `${data.passFrom}${this.suffix}` };
  }

  private passSelectItems(): Observable<SelectItem<string>[]> {
    return of([
      new SelectItem("30%", "30%"),
      new SelectItem("40%", "40%"),
      new SelectItem("50%", "50%"),
    ]);
  }
}
