import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { AuthToken } from "../../interfaces/auth-token";
import { Token } from "../../types/token";
import { ApiService } from "../api/api.service";
import { AuthKeyclockUrl } from "../../interfaces/auth-keyclock";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private apiService: ApiService,
    private router: Router,
  ) {}

  login(credentials: {
    username: string;
    password: string;
  }): Observable<AuthToken> {
    return this.apiService.postRequest<AuthToken>("token/", credentials);
  }

  saveToStorage(authToken: AuthToken, refreshed = false) {
    if (refreshed) {
      (<AuthToken>(
        JSON.parse(window.localStorage.getItem("cyber-skiller-token"))
      )).access = authToken.access;
    } else {
      window.localStorage.setItem(
        "cyber-skiller-token",
        JSON.stringify(authToken),
      );
    }
  }

  getFromStorage(): Observable<AuthToken> {
    return of(JSON.parse(window.localStorage.getItem("cyber-skiller-token")));
  }

  logout() {
    if (
      !this.router.url.includes("/account/login") &&
      !this.router.url.includes("/account/redeem/")
    ) {
      this.router.navigate(["/account/login"]);
    }
    window.localStorage.removeItem("cyber-skiller-token");
  }

  refreshAccessToken(token: Token): Observable<AuthToken> {
    return this.apiService.postRequest<AuthToken>("token/refresh/", {
      refresh: token,
    });
  }

  loginKeyclock(provider: string): Observable<AuthKeyclockUrl> {
    const params = new HttpParams().set("provider", provider);

    return this.apiService.getRequest("auth/login_url", { params });
  }

  exchangeCodeForToken(code: string, state: string): Observable<AuthToken> {
    const params = new HttpParams().set("code", code).set("state", state);

    return this.apiService.getRequest("auth/callback", { params });
  }
}
