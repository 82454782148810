import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap,
} from "rxjs/operators";
import {
  statsCriteriaLoad,
  statsCriteriaLoadFailure,
  statsCriteriaLoadSuccess,
} from "../actions/stats/stats-criteria.actions";
import { PassCriteriaService } from "src/app/shared/services/pass-criteria/pass-criteria.service";
import {
  Config,
  CourseCriteria,
} from "src/app/shared/interfaces/student-pass-criteria-api";
import {
  statsCriteriaEditLoad,
  statsCriteriaEditLoadFailure,
  statsCriteriaEditLoadSuccess,
} from "../actions/stats/stats-criteria-edit.actions";
import {
  statsCriteriaAllEditLoad,
  statsCriteriaEditAllLoadFailure,
  statsCriteriaEditAllLoadSuccess,
} from "../actions/stats/stats-crtieria-all-edit.actions";

@Injectable()
export class StatsEffect {
  getStatsCriteria$ = createEffect(() =>
    this.actions$.pipe(
      ofType(statsCriteriaLoad),
      switchMap((param) => {
        return this.passCriteria
          .getCriteriaForCourseGroup(param.CourseId, param.GroupId)
          .pipe(
            map((criteria: CourseCriteria) =>
              statsCriteriaLoadSuccess({ criteria }),
            ),
            catchError(({ error }) => of(statsCriteriaLoadFailure(error))),
          );
      }),
    ),
  );

  editStatsCriteria$ = createEffect(() =>
    this.actions$.pipe(
      ofType(statsCriteriaEditLoad),
      switchMap((param) =>
        this.passCriteria
          .editPartialGrade(
            param.CourseId,
            param.GroupId,
            param.criteria,
            param.GarlicId,
          )
          .pipe(
            mergeMap((criteria: Config) => [
              statsCriteriaEditLoadSuccess({ criteria }),
              statsCriteriaLoad({
                CourseId: param.CourseId,
                GroupId: param.GroupId,
              }),
            ]),
            catchError(({ error }) => of(statsCriteriaEditLoadFailure(error))),
          ),
      ),
    ),
  );

  editStatsCriteriaAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(statsCriteriaAllEditLoad),
      exhaustMap((param) =>
        this.passCriteria
          .saveCriteriaForCourseGroup(
            param.CourseId,
            param.GroupId,
            param.criteria,
          )
          .pipe(
            mergeMap((criteria: CourseCriteria) => [
              statsCriteriaEditAllLoadSuccess({ criteria }),
              statsCriteriaLoad({
                CourseId: param.CourseId,
                GroupId: param.GroupId,
              }),
            ]),
            catchError(({ error }) =>
              of(statsCriteriaEditAllLoadFailure(error)),
            ),
          ),
      ),
    ),
  );

  constructor(
    private passCriteria: PassCriteriaService,
    private actions$: Actions,
  ) {}
}
